@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Font/GothamBook.ttf") format("truetype")
}

body {
  margin: 0;
  font-family: Gotham;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /*Firefox */
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-card-body {
  padding: 0 !important;
}

.slick-slide {
  display: flex !important;
  justify-content: center !important;
}

@media(min-width: 768px) {
  .slick-slide {
    display: block !important;
  }
}